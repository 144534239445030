import React, { useState, useEffect, useContext } from "react"
import { Link } from "gatsby";
import styled from "styled-components"
import queryString from 'query-string'
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';
import { faEnvelope, faCheck } from "@fortawesome/free-solid-svg-icons";

import SEO from "../components/seo"
import Container from "../components/layout/Container"
import Layout2Columns from "../components/layout/Layout2Columns";
import Page from "../components/layout/Page"
import PageHeader from "../components/layout/PageHeader"
import Loading from "../components/Loading"
import MajorError from "../components/MajorError"
import SignPreviewImage from "../components/SignPreviewImage"
import Field from "../components/fields/Field"
import TextField from "../components/fields/TextField"
import MultiTextField from "../components/fields/MultiTextField"
import EmailField from "../components/fields/EmailField"
import PhoneField from "../components/fields/PhoneField"
import UploadButton from "../components/buttons/UploadButton"

import { postData } from '../services/server'
import HoverButton from "../components/buttons/HoverButton";

import { devices, colors } from "../settings";
import GoogleImage from "../components/GoogleImage";

import grace from "../images/employees/grace.png"
import { isValidPhoneNumber } from "../utilities/validation";

const SignPreview = styled.div`
  border-radius: 5px;
  min-height: 310px;

  @media ${devices.mobile} {
    min-height: auto;
  }
`
const Project = styled.div`
	svg {
		color: ${colors.green};
	}
`

const CustomizeSignPage = (props) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [busy, setBusy] = useState("");
  const [sign, setSign] = useState(null);
  const [project, setProject] = useState(null);

  const [data, setData] = useState({
      name: "",
      phoneno: "",
      email: "",
      customizations: ""
  });
  const [errors, setErrors] = useState({
	});

  useEffect(
      () => {
          setLoading(true);
  
          const params = queryString.parse(typeof window !== `undefined` ? window.location.search : "")
  
          if (params.uid || params.id) {
            postData("/api/builder/signdata" + (params.id ? "?id=" + params.id:""), params,
                (result) => {
                    setSign(result);      
                    setData({
                      name: result.user_name,
                      phoneno: result.user_phone,
                      email: result.user_email,
                      customizations: ""                      
                    })          
                },
                (error, status) => {
                  if ((error.response && error.response.status == 404) || status == 404)
                    setError("Unable to locate the requested sign.");
                  else
                    setError("An error occured while loading the sign.");
                },
                () => {
                    setLoading(false);
                }
            ); 
          }
          else setError("Invalid sign.")                 
      }, 
      []
  );
    
  function onFieldChange(e) {
    setError(null);
    setData({...data, [e.target.name]: e.target.value});
  }
  
  function validateForm() {
      const nameValid = data.name && data.name.length > 0;
      const emailValid = data.email && data.email.length > 0;
      const customizationsValid = data.customizations && data.customizations.length > 0;
      const phoneValid = data.phoneno ? (data.phoneno.length == 0 || isValidPhoneNumber(data.phoneno)) : true;

      setErrors({
        name: !nameValid,
        email: !emailValid,
        customizations: !customizationsValid,
        phoneno: !phoneValid
      });

      return nameValid && emailValid && customizationsValid && phoneValid;
	}
	  
  function onSubmit() {
    const valid = validateForm();

    if (valid) {
      setBusy("submitting")

      postData("/api/projects/submit", {
          contact_name: data.name,
          contact_phoneno: data.phoneno,
          contact_email: data.email, 
          from: "CustomizeSign",
          sign_id: sign.id,
          user_id: sign.user_id,
          description: data.customizations,
          attachment1_id: data.attachment1 ? data.attachment1.id : null
      }, 
      (response) => {
          setProject(response);
      }, 
      (error, status) => {
          setError("An error occured while saving your request.  Please give us a call at " + phones.sales + ".")
      },
      () => {
          setBusy("");
      });  
    }
    else {
			setError("Please correct the highlighted problems.")
    }
  }

  return (
    <>
      <SEO page="sign" title={sign ? "Sign Design #"+sign.id : "Sign Details"} />
      <Page 
        breadcrumb={[
            { url: "/", title: "Home"},
            { url: "/account/signs", title: "My Signs"},
            { url: null, title: "Customize Sign" }
        ]}
      >
        <PageHeader 
                title={sign ? "Sign Design #" + sign.id : "Loading Sign..."} 
                smalltitle={sign ? " V" + sign.version + (sign.title ? " - " + sign.title : "") : ""}
                subtitle={sign ? "Created on <strong>" + sign.added_date_formatted + "</strong> Estimated Size: <strong>" + sign.size + "</strong>, " + sign.area + " square feet" : ""}
            />

        { loading ?
            <Loading label="Loading sign..." style={{margin: "50px" }} />
          : sign ?
            <>  
              <Container>
                  <Layout2Columns align="top" left={65} right={35}>
                    <div>
                      <SignPreview>
                        <SignPreviewImage root src={sign.preview_url} responsive style={{ height: "auto", maxWidth: "100%", maxHeight: "400px", display: "block", margin: "10px auto" }} />
                      </SignPreview>
                    </div>

                    {project ? 
                        <Project>
                            <div>
                                <p>
                                    <FontAwesomeSvgIcon icon={faCheck} />{' '} We've received your request.  
                                </p>
                                <p>
                                    We'll contact you soon with your updated sign and price.
                                </p>
                                <p>
                                    Your sign customization request number is <strong>#{project.id}</strong>.
                                </p>
                                <p>
                                    You can access your customization request via the following link.  We've also emailed
                                    it to you.
                                </p>
                                <p>
                                    <Link to={"/account/project/?uid=" + project.uid}>
                                        {process.env.GATSBY_SITE_URL}/account/project/?uid={project.uid}
                                    </Link>
                                </p>
                            </div>
                        </Project>
                    :                    
                        <div>
                            <p style={{display: "flex"}}>
                              <img src={grace} alt="Sign Expert" height="35" style={{marginRight: "6px"}} />
                              <div>
                                Fill out the form below to request help with your sign or give us a call 
                                at <a href="tel:1-304-399-3503"><strong>(304) 399-3503</strong></a> to talk to a sign expert.                              
                              </div>
                            </p>
                            <div>
                                <TextField 
                                    value={data.name}
                                    wide
                                    error={errors.name === true}
                                    name="name"
                                    label="Name"
                                    onChange={onFieldChange} 
                                    required 
                                />
                            </div>
                            <div>
                                <PhoneField 
                                    value={data.phoneno}
                                    wide
                                    error={errors.phoneno === true}
                                    name="phoneno"
                                    label="Phone Number"
                                    onChange={onFieldChange} 
                                />
                            </div>  
                            <div>
                                <EmailField
                                    value={data.email}
                                    wide
                                    error={errors.email === true}
                                    name="email"
                                    label="Email"
                                    onChange={onFieldChange} 
                                    required 
                                />
                            </div>  
                            
                            <div>
                                <MultiTextField 
                                    value={data.customizations}
                                    wide
                                    error={errors.customizations === true}
                                    name="customizations"
                                    label="Describe Customizations"
                                    labelBold={true}
                                    onChange={onFieldChange} 
                                    rows={3}
                                    required 
                                />
                            </div>   

                            <div>
                              <Field label="Existing Logo Or Graphics">
                                  <UploadButton 
                                    uploadUrl="/api/projects/upload"
                                    buttonStyle={{width: "auto", marginRight: "10px", fontSize: "14px"}}
                                    onUpload={(upload) => {
                                      setData({...data, attachment1: upload});
                                    }}
                                  />
                                  { data.attachment1 && 
                                    <>
                                      {data.attachment1.url_thumb ? 
                                        <GoogleImage root src={data.attachment1.url_thumb} required lazy style={{height: "25px", width: "auto"}} />
                                        :
                                        <FontAwesomeSvgIcon icon={faFile} />
                                      }
                                      &nbsp;
                                      {data.attachment1.filename}
                                    </>
                                  }
                              </Field>                              
                            </div>

                            {error && 
                              <p style={{color:"red"}}>
                                {error}
                              </p>
                            }

                            <div style={{marginTop: "15px"}}>
                                <HoverButton
                                    label="Submit"
                                    color="orange"
                                    icon={faEnvelope}
                                    wide
                                    busy={busy == "submitting"}
                                    onClick={onSubmit}
                                />
                            </div>                                                                                         
                        </div>
                    }
                </Layout2Columns>
              </Container>
            </>
          :
            <div style={{ fontSize: "25px", textAlign: "center", margin: "50px", color: "#ccc"}}>
              Sign Not Found
            </div>
        }

        { (false && error && error != "Unable to locate the requested sign.") &&
            <MajorError 
              error={error} 
              log={error}
              onClose={() => setError(null)}
            />
        }
      </Page>
    </>
  )
}

export default CustomizeSignPage
